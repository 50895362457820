import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useCallback,
  useContext,
  useState,
} from "react";
import { Modal, Row, Spinner } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { exportToDoc } from "../../../../api/ingestion";
import CustomButton from "../../../../components/CustomComponent/CustomButton";
import { TAppContext, AppContext } from "../../../../context/AppContext";
import {
  TAssessmentContext,
  AssessmentContext,
} from "../../../../context/AssessmentContext";
import {
  EQuestionType,
  ETemplateType,
  ESourceType,
} from "../../../../utils/Enums";
import {
  uploadFiles,
  validateMarks,
  validateSection,
  validateOrg,
  getS3URL,
  refetchData,
} from "../AddQuestionButton/AddQuestionHelpers";
import { TContextProps } from "../AddQuestionButton/ManualQuestionHelpers";
import styles from "./ExportDocModal.module.scss";
import { toast } from "react-toastify";

interface ExportToDocModalProps {
  showModal: boolean;
  setShowModal: Dispatch<SetStateAction<boolean>>;
  onSuccess?: (s3Url: string) => void;
}

const ExportToDocModal: FunctionComponent<ExportToDocModalProps> = (props) => {
  const { showModal, setShowModal } = props;
  const { org, accessToken } = useContext<TAppContext>(AppContext);

  const {
    sectionMap,
    setSectionMap,
    currentSectionNumber,
    setCurrentSectionNumber,
    setCurrentQuestionNumber,
    selectedAssessment,
    questionMap,
    setQuestionMap,
    setComprehensionMap,
  } = useContext<TAssessmentContext>(AssessmentContext);

  const selectedSectionId = sectionMap.get(
    selectedAssessment.sections[currentSectionNumber]
  )!.id;
  const marksIfCorrect = 4;
  const marksIfIncorrect = -1;
  const questionType = EQuestionType.MCQ;

  const [loading, setLoading] = useState<boolean>(false);

  const [files, setFiles] = useState<any>([]);

  const contextProps: TContextProps = {
    sectionMap,
    setSectionMap,
    questionMap,
    setQuestionMap,
    setCurrentSectionNumber,
    setCurrentQuestionNumber,
    setComprehensionMap,
  };

  const closeModal = () => {
    setFiles([]);
    setShowModal(false);
  };

  document.onpaste = function (event) {
    if (event.clipboardData && props.showModal) {
      const items = event.clipboardData.files;
      const file = items[0];
      if (file) {
        setFiles([
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ]);
      }
    }
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    // Do something with the files
    setFiles(
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      )
    );
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: "image/*",
    onDrop,
    multiple: false,
  });
  const preview = files.map((file) => (
    <div key={file.name}>
      <div>
        <img
          src={file.preview}
          style={{ display: "block", width: "100%", height: "auto" }}
          alt="preview_image"
        />
      </div>
    </div>
  ));

  // TODO: Add a loading screen while image is being uploaded
  return (
    <Modal
      show={showModal}
      onHide={() => closeModal()}
      contentClassName={styles.modalStyle}
    >
      <Modal.Header closeButton>
        <Modal.Title>Screenshot To Question</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {/* Image selection */}
        <Row className="mt-2" key="image">
          <div
            style={{ height: "150px" }}
            {...getRootProps({ className: styles.dropzone })}
          >
            <input {...getInputProps()} />
            {isDragActive ? (
              <p>Drop the files here ...</p>
            ) : (
              <div className="text-center">
                <p>
                  Drag 'n' drop or click to select image.
                  <br /> <br />
                  Click Submit and wait while we convert your screenshot into an
                  editable question.
                </p>
              </div>
            )}
          </div>
          <aside>{preview}</aside>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <CustomButton
          loading={loading}
          onClick={async () => {
            setLoading(true);
            const fileHash = await uploadFiles(files);
            if (!fileHash) {
              setLoading(false);
              return;
            }
            if (
              !validateMarks(marksIfCorrect, marksIfIncorrect) ||
              !validateSection(contextProps.sectionMap, selectedSectionId) ||
              !validateOrg(org)
            ) {
              setLoading(false);
              return;
            }

            const s3Url = await exportToDoc(accessToken, {
              template: ETemplateType.IMAGE_TEMPLATE1,
              convert_docx: true,
              expected_question_num: 1,
              hash: fileHash,
              org_id: org!!.id,
              name: org!!.name,
              source_type: ESourceType.SCREENSHOT,
              object_store_url: getS3URL(fileHash + "/"), // string from question Hash
              question_metadata: [
                {
                  start: 1,
                  end: 1,
                  type: questionType,
                  subject: "undefined",
                },
              ],
              marking_scheme: [
                {
                  marks_if_correct: Number(marksIfCorrect),
                  marks_if_incorrect: Number(marksIfIncorrect),
                  marks_if_unattempted: 0,
                  partial_marking: false,
                },
              ],
              assessment_id: selectedAssessment.id,
              section_id: selectedSectionId,
              comprehension_id: undefined,
            });
            if (s3Url == null) {
              setLoading(false);
              toast("Failed to export image");
              return;
            }

            if (props.onSuccess) {
              props.onSuccess(s3Url);
            }
            refetchData(
              accessToken,
              selectedAssessment,
              selectedSectionId,
              contextProps
            );
            setLoading(false);
            closeModal();
          }}
        >
          {loading ? <Spinner animation="border" role="status" /> : "Submit"}
        </CustomButton>
      </Modal.Footer>
    </Modal>
  );
};

export default ExportToDocModal;
