import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  Box,
  useMediaQuery,
} from "@mui/material";
import {
  Dispatch,
  FunctionComponent,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { Navbar } from "react-bootstrap";
import SettingsIcon from "@mui/icons-material/Settings";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import { useHistory } from "react-router-dom";
import ManageAssessmentBottomDrawer from "./BottomDrawer";
import EditIcon from "@mui/icons-material/Edit";
import DriveFileRenameOutlineIcon from "@mui/icons-material/DriveFileRenameOutline";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import DeleteIcon from "@mui/icons-material/Delete";
import PrintIcon from "@mui/icons-material/Print";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../../../context/AssessmentContext";
import { EAssessmentFormat } from "../../../../utils/Enums";
import { Marquee } from "../../../../components/CustomComponent/Marquee";
import { displayErrorToast } from "../../../../utils/ToastUtils";

interface ManageAssessmentNavBarProps {
  assessmentName: string;
  setShowEditAssessmentNameModal: Dispatch<SetStateAction<boolean>>;
  setShowDeleteAssessmentModal: Dispatch<SetStateAction<boolean>>;
  setShowEditInstructionsModal: Dispatch<SetStateAction<boolean>>;
  setShowPrintAssessmentModal: Dispatch<SetStateAction<boolean>>;
  handleOpenTest: () => void;
}

const ManageAssessmentNavBar: FunctionComponent<
  ManageAssessmentNavBarProps
> = ({
  assessmentName,
  setShowEditAssessmentNameModal,
  setShowDeleteAssessmentModal,
  setShowEditInstructionsModal,
  setShowPrintAssessmentModal,
  handleOpenTest,
}) => {
  const history = useHistory();
  const isMobile = useMediaQuery("(max-width: 576px)");
  const isDesktopOrLaptop = useMediaQuery("(min-width: 992px)");
  const [assessmentSettingsAnchor, setAssessmentSettingsAnchor] =
    useState<null | HTMLElement>(null);
  const assessmentState = useContext<TAssessmentContext>(AssessmentContext);
  const handleClose = () => setAssessmentSettingsAnchor(null);

  return (
    <Navbar
      className="d-flex bg-primary p-3"
      style={{
        height: "8%",
        width: "100%",
        zIndex: 15,
      }}
    >
      <div className="d-flex w-100 flex-row justify-content-between align-items-center mt-2">
        <div className="d-flex flex-row align-items-center">
          <IconButton
            className="mb-2"
            onClick={() => {
              history.replace("/home/dashboard");
            }}
          >
            <IoArrowBackCircleOutline
              size={"35px"}
              color="white"
              cursor={"pointer"}
            />
          </IconButton>
          <Typography
            variant={isDesktopOrLaptop ? "h4" : "h6"}
            className={"m-2"}
            color="white"
          >
            <Marquee disable={!isMobile}>{assessmentName}</Marquee>
          </Typography>
        </div>
        <IconButton
          className="ms-3 mb-2"
          onClick={(e) => {
            setAssessmentSettingsAnchor(e.currentTarget);
          }}
        >
          <SettingsIcon htmlColor="white" />
        </IconButton>
        {!isMobile && (
          <Menu
            sx={{
              ".MuiMenu-paper": { backgroundColor: "#F0F0F0" },
            }}
            open={Boolean(assessmentSettingsAnchor)}
            anchorEl={assessmentSettingsAnchor}
            onClose={() => setAssessmentSettingsAnchor(null)}
          >

          <MenuItem
            onClick={() => {
              if (assessmentState.sectionMap.size === 0) {
                displayErrorToast("Please check if a section has been created, or create one first");
                return;
              }
              history.replace("/home/assessments/" + assessmentState.selectedAssessment.id + "/export_to_doc_mode");
              handleClose();
            }}
          >
            <ListItemText>Export to Word</ListItemText>
          </MenuItem>
            {assessmentState.selectedAssessment.format ===
              EAssessmentFormat.NTA && (
              <MenuItem
                sx={{
                  "&:hover": { backgroundColor: "#292F4D", color: "#FFFFFF" },
                }}
                onClick={() => {
                  setShowPrintAssessmentModal(true);
                  handleClose();
                }}
              >
                Print the paper
              </MenuItem>
            )}
            <MenuItem
              sx={{
                "&:hover": { backgroundColor: "#292F4D", color: "#FFFFFF" },
              }}
              onClick={() => {
                setShowEditAssessmentNameModal(true);
                handleClose();
              }}
            >
              Edit Test Name
            </MenuItem>
            <MenuItem
              sx={{
                "&:hover": { backgroundColor: "#292F4D", color: "#FFFFFF" },
              }}
              onClick={() => {
                setShowEditInstructionsModal(true);
                handleClose();
              }}
            >
              Edit Test Instructions
            </MenuItem>
            <MenuItem
              onClick={() => {
                setShowDeleteAssessmentModal(true);
                handleClose();
              }}
              sx={{
                "&:hover": { backgroundColor: "#292F4D", color: "#FFFFFF" },
              }}
            >
              Delete Test
            </MenuItem>
          </Menu>
        )}
      </div>
      {isMobile && (
        <ManageAssessmentBottomDrawer
          anchorEl={assessmentSettingsAnchor}
          setAnchorEl={setAssessmentSettingsAnchor}
          assessmentName={assessmentName}
        >
          {assessmentState.selectedAssessment.format ===
            EAssessmentFormat.NTA && (
            <MenuItem
              onClick={() => {
                setShowPrintAssessmentModal(true);
                handleClose();
              }}
            >
              <ListItemIcon>
                <PrintIcon />
              </ListItemIcon>
              <ListItemText>Print the Paper</ListItemText>
            </MenuItem>
          )}
          <MenuItem
            onClick={() => {
              handleOpenTest();
              handleClose();
            }}
          >
            <ListItemIcon>
              <OpenInNewIcon color="success" />
            </ListItemIcon>
            <ListItemText>Open Test</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setShowEditInstructionsModal(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <EditIcon htmlColor="#22343C" />
            </ListItemIcon>
            <ListItemText>Test Instructions</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setShowEditAssessmentNameModal(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <DriveFileRenameOutlineIcon color="primary" />
            </ListItemIcon>
            <ListItemText>Rename</ListItemText>
          </MenuItem>

          <MenuItem
            onClick={() => {
              setShowDeleteAssessmentModal(true);
              handleClose();
            }}
          >
            <ListItemIcon>
              <DeleteIcon color="error" />
            </ListItemIcon>
            <ListItemText>Delete Test</ListItemText>
          </MenuItem>
        </ManageAssessmentBottomDrawer>
      )}
    </Navbar>
  );
};

export default ManageAssessmentNavBar;
