import { useFormik } from "formik";
import { useContext, useState } from "react";
import { Col, Row, Form, Spinner } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { ingestQuestion } from "../../api/ingestion";
import CustomButton from "../../components/CustomComponent/CustomButton";
import { AppContext, TAppContext } from "../../context/AppContext";
import {
  AssessmentContext,
  TAssessmentContext,
} from "../../context/AssessmentContext";
import { EQuestionType, ESourceType, ETemplateType } from "../../utils/Enums";
import { displayErrorToast } from "../../utils/ToastUtils";
import {
  getS3URL,
  refetchData,
  uploadFiles,
  validateMarks,
  validateOrg,
  validateSection,
} from "../question/Components/AddQuestionButton/AddQuestionHelpers";
import { TContextProps } from "../question/Components/AddQuestionButton/ManualQuestionHelpers";

export default function IngestionView() {
  const {
    sectionMap,
    setSectionMap,
    selectedAssessment,
    questionMap,
    setQuestionMap,
    setCurrentSectionNumber,
    setCurrentQuestionNumber,
    setComprehensionMap,
  } = useContext<TAssessmentContext>(AssessmentContext);
  const { org, accessToken } = useContext<TAppContext>(AppContext);

  const history = useHistory();
  const [files, setFiles] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  const contextProps: TContextProps = {
    sectionMap: sectionMap,
    setSectionMap: setSectionMap,
    questionMap: questionMap,
    setQuestionMap: setQuestionMap,
    setCurrentSectionNumber: setCurrentSectionNumber,
    setCurrentQuestionNumber: setCurrentQuestionNumber,
    setComprehensionMap,
  };

  const onUpload = (onUploadEvent: any) => {
    const fileList = onUploadEvent.target.files;
    if (fileList === 0) {
      displayErrorToast("Please submit only an image file");
      return;
    }
    setFiles(fileList);
  };

  const formik = useFormik({
    initialValues: {
      template: ETemplateType.IMAGE_TEMPLATE1,
      qType: EQuestionType.MCQ,
      sectionId: selectedAssessment.sections[0],
      subject: "undefined",
      marksIfCorrect: 4,
      marksIfIncorrect: -1,
      questionRegex: "",
      optionRegex: "",
      answerRegex: "",
      solutionRegex: "",
      file: "",
    },
    onSubmit: async (values) => {
      alert(JSON.stringify(values, null, 2));
      setLoading(true);
      const fileHash = await uploadFiles(files);
      if (!fileHash) {
        setLoading(false);
        return;
      }
      if (
        !validateMarks(values.marksIfCorrect, values.marksIfIncorrect) ||
        !validateSection(sectionMap, values.sectionId) ||
        !validateOrg(org)
      ) {
        setLoading(false);
        return;
      }
      const success = await ingestQuestion(accessToken, {
        template: values.template,
        template_regex: {
          question_regex: values.questionRegex,
          option_regex: values.optionRegex,
          answer_regex: values.answerRegex,
          solution_regex: values.solutionRegex,
          question_option_regex: "qOption",
        },
        expected_question_num: 1,
        hash: fileHash,
        org_id: org!!.id,
        name: org!!.name,
        source_type: ESourceType.SCREENSHOT,
        object_store_url: getS3URL(fileHash + "/"), // string from question Hash
        question_metadata: [
          {
            start: 1,
            end: 1,
            type: values.qType,
            subject: "undefined",
          },
        ],
        marking_scheme: [
          {
            marks_if_correct: Number(values.marksIfCorrect),
            marks_if_incorrect: Number(values.marksIfIncorrect),
            marks_if_unattempted: 0,
            partial_marking: false,
          },
        ],
        assessment_id: selectedAssessment.id,
        section_id: values.sectionId,
        comprehension_id: undefined,
        convert_docx: true
      });
      if (!success) {
        setLoading(false);
        return;
      }
      refetchData(
        accessToken,
        selectedAssessment,
        values.sectionId,
        contextProps
      );
      setLoading(false);
      console.log("Done");
      history.push(`/home/assessments/${selectedAssessment.id}/question`);
    },
  });

  return (
    <Row className="rounded-4 w-100">
      <Form
        className="mt-4 p-5 d-flex flex-column"
        onSubmit={formik.handleSubmit}
      >
        <Row className="mt-2 mb-2">
          {/* Choosing the correct template */}
          <Form.Group controlId="template">
            <Row>
              <Col className="col-3">
                <Form.Label className="d-flex justify-content-start" column>
                  <b>Choose Template</b>
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
              </Col>
              <Col className="col-5">
                <Form.Control
                  className="bg-light form-select"
                  as="select"
                  value={formik.values.template}
                  onChange={formik.handleChange}
                >
                  <option value={ETemplateType.IMAGE_TEMPLATE1}>
                    {" "}
                    Image w/ 1+ Questions
                  </option>
                </Form.Control>
              </Col>
            </Row>
          </Form.Group>
        </Row>
        <Row className="mt-2 mb-2">
          {/* Choosing Question Type */}
          <Form.Group controlId="qType">
            <Row>
              <Col className="col-3">
                <Form.Label className="d-flex justify-content-start" column>
                  <b>Question Type</b>
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
              </Col>
              <Col className="col-5">
                <Form.Control
                  className="bg-light form-select"
                  as="select"
                  value={formik.values.qType}
                  onChange={formik.handleChange}
                >
                  <option value={EQuestionType.MCQ}> MCQ </option>
                  <option value={EQuestionType.NUMERICAL}> Numerical </option>
                </Form.Control>
              </Col>
            </Row>
          </Form.Group>
        </Row>
        <Row className="mt-2 mb-2">
          <Col className="col-6">
            {/* Choosing Section */}
            <Form.Group controlId="sectionId">
              <Row>
                <Col className="col-4">
                  <Form.Label className="d-flex justify-content-start" column>
                    <b>Section Name</b>
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                </Col>
                <Col className="col-5">
                  <Form.Control
                    className="bg-light form-select"
                    as="select"
                    value={formik.values.sectionId}
                    onChange={formik.handleChange}
                  >
                    {selectedAssessment.sections.map((el) => (
                      <option value={el}>{sectionMap.get(el)!.name}</option>
                    ))}
                  </Form.Control>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            {/* Choosing Section */}
            <Form.Group controlId="subject">
              <Row>
                <Col className="col-4">
                  <Form.Label className="d-flex justify-content-start" column>
                    <b>Subject</b>
                  </Form.Label>
                </Col>
                <Col className="col-5">
                  <Form.Control
                    className="bg-light"
                    type="text"
                    value={formik.values.subject}
                    onChange={formik.handleChange}
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2 mb-2">
          {/* Setting Marks */}
          <Col className="col-6">
            <Form.Group controlId="marksIfCorrect">
              <Row>
                <Col className="col-4">
                  <Form.Label className="d-flex justify-content-start" column>
                    <b>Marks If Correct</b>
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                </Col>
                <Col className="col-5">
                  <Form.Control
                    className="bg-light"
                    type="number"
                    value={formik.values.marksIfCorrect}
                    onChange={formik.handleChange}
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="marksIfIncorrect">
              <Row>
                <Col className="col-4">
                  <Form.Label className="d-flex justify-content-start" column>
                    <b>Marks If Incorrect</b>
                    <span style={{ color: "red" }}>*</span>
                  </Form.Label>
                </Col>
                <Col className="col-5">
                  <Form.Control
                    className="bg-light"
                    type="number"
                    value={formik.values.marksIfIncorrect}
                    onChange={formik.handleChange}
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        {/* Various Regexes */}
        <Row className="mt-2 mb-2">
          <Col className="col-6">
            <Form.Group controlId="questionRegex">
              <Row>
                <Col className="col-4">
                  <Form.Label className="d-flex justify-content-start" column>
                    <b>Question Regex</b>
                  </Form.Label>
                </Col>
                <Col className="col-5">
                  <Form.Control
                    className="bg-light"
                    type="text"
                    value={formik.values.questionRegex}
                    onChange={formik.handleChange}
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="optionRegex">
              <Row>
                <Col className="col-4">
                  <Form.Label className="d-flex justify-content-start" column>
                    <b>Option Regex</b>
                  </Form.Label>
                </Col>
                <Col className="col-5">
                  <Form.Control
                    className="bg-light"
                    type="text"
                    value={formik.values.optionRegex}
                    onChange={formik.handleChange}
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2 mb-2">
          <Col className="col-6">
            <Form.Group controlId="answerRegex">
              <Row>
                <Col className="col-4">
                  <Form.Label className="d-flex justify-content-start" column>
                    <b>Answer Regex</b>
                  </Form.Label>
                </Col>
                <Col className="col-5">
                  <Form.Control
                    className="bg-light"
                    type="text"
                    value={formik.values.answerRegex}
                    onChange={formik.handleChange}
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>
          </Col>
          <Col className="col-6">
            <Form.Group controlId="solutionRegex">
              <Row>
                <Col className="col-4">
                  <Form.Label className="d-flex justify-content-start" column>
                    <b>Solution Regex</b>
                  </Form.Label>
                </Col>
                <Col className="col-5">
                  <Form.Control
                    className="bg-light"
                    type="text"
                    value={formik.values.solutionRegex}
                    onChange={formik.handleChange}
                  ></Form.Control>
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row className="mt-2 mb-2">
          {/* Choosing the correct template */}
          <Form.Group controlId="file">
            <Row>
              <Col className="col-3">
                <Form.Label className="d-flex justify-content-start" column>
                  <b>Upload File</b>
                  <span style={{ color: "red" }}>*</span>
                </Form.Label>
              </Col>
              <Col className="col-5">
                <Form.Control
                  className="bg-light"
                  type="file"
                  onChange={onUpload}
                ></Form.Control>
              </Col>
            </Row>
          </Form.Group>
        </Row>
        <CustomButton onClick={formik.handleSubmit} loading={loading}>
          Submit
        </CustomButton>
      </Form>
    </Row>
  );
}
