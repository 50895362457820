import { EQuestionStatus, EQuestionType } from "../utils/Enums";
import { IQuestionBody } from "./common";

export interface IQuestionMarkingScheme {
  question_id: number;
  marks_if_correct: number;
  marks_if_incorrect: number;
  marks_if_unattempted: number;
  partial_marking: boolean;
}

export class newQuestionObj {
  subject: string;
  body: IQuestionBody;
  options: Array<IQuestionBody>;
  answer: string;
  solution: IQuestionBody;
  status: EQuestionStatus;
  question_type: EQuestionType;
  comprehension_id: number | null;

  public constructor(
    status: EQuestionStatus,
    type: EQuestionType,
    optionCount: number,
    comprehension_id?: number
  ) {
    const emptyContent = { text: "", images: [] };
    this.subject = "";
    this.body = emptyContent;
    this.options = Array(optionCount).fill(emptyContent);
    this.answer = "";
    this.solution = emptyContent;
    this.status = status;
    this.question_type = type;
    this.comprehension_id = comprehension_id || null;
  }
}

export interface IQuestion {
  id: number;
  hash: string;
  subject: string;
  body: IQuestionBody;
  options: Array<IQuestionBody>;
  answer: string;
  solution: IQuestionBody;
  status: EQuestionStatus;
  question_type: EQuestionType;
}

export interface IQuestionV2 {
  subject: string;
  body: IQuestionBody;
  options: Array<IQuestionBody>;
  answer: string;
  solution: IQuestionBody;
  status: number;
  question_type: number;
  duplicated_from: number;
  comprehension_id: number;
  id: number;
}
