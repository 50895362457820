import { useState, useContext, useEffect, Dispatch, SetStateAction } from "react";
import { Form, Button } from "react-bootstrap";
import { AppContext, TAppContext } from "../../context/AppContext";
import { displaySuccessToast } from "../../utils/ToastUtils";
import { useHistory } from "react-router";
import * as yup from "yup";
import { Formik } from "formik";
import {
  getEnumRoleValueFromString,
  getStringFromEnumRoleValue,
} from "../../utils/Enums";
import { fetchUser, updateUserAdditionalInfoAPICall } from "../../api/user";
import { IUserInfo } from "../../models/usermodel";
import { EAuthMode } from "./Components/AuthScreen";

interface TProps {
  setAuthMode?: Dispatch<SetStateAction<EAuthMode>>;
}

export default function AdditionalInfo(props: TProps) {
  const {
    role,
    setRole,
    accessToken,
    setName,
    showLoginModal,
    setIsPhoneNumberValid,
  } = useContext<TAppContext>(AppContext);

  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [userData, setUserData] = useState<IUserInfo>();

  let schema = yup.object().shape({
    name: yup.string().required("Please enter a name"),
    city: yup.string().required("Please enter a city"),
    phone: yup
      .string()
      .required("Please enter your number")
      .matches(/^[0-9]+$/, "Phone must be a number")
      .test("len", "Must be exactly 10 digits", (val) => val?.length === 10),
    dob: yup.date().required("Please enter a date"),
  });

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      const userInfo = await fetchUser(accessToken);
      setUserData(userInfo);
      setIsLoading(false);
    })();
  }, []);

  const handleAdditionalInfo = async (values: any) => {
    let response = await updateUserAdditionalInfoAPICall(
      values.name,
      values.city,
      values.phone,
      values.dob,
      accessToken
    );
    if (!response) {
      return;
    }
    setRole(getEnumRoleValueFromString(values.role));
    setIsPhoneNumberValid(true);
    displaySuccessToast("Successfully Updated Your Profile");
  };

  return isLoading ? (
    <div />
  ) : (
    <div className="container d-flex justify-content-center w-100 p-0 mt-0">
      <div
        className={
          "col-sm-8 col-md-8 col-lg-6 col-11 " +
          (showLoginModal ? "w-100" : null)
        }
      >
        <div
          className="login shadow-lg rounded-2"
          style={{
            marginTop: showLoginModal ? 0 : "10%",
            paddingLeft: "4%",
            paddingRight: "4%",
            paddingTop: "4%",
            paddingBottom: "4%",
            backgroundColor: "#eeeeee",
          }}
        >
          <h2 className="d-flex justify-content-center">
            <strong>My Profile</strong>
          </h2>
          <hr className="mt-4 text-primary" style={{ width: "100%" }} />

          <Formik
            validationSchema={schema}
            onSubmit={async (values) => {
              await handleAdditionalInfo(values);
              if(props.setAuthMode === undefined) {
                history.push("/home");
              }
            }}
            initialValues={{
              name: userData?.name ?? "",
              role: getStringFromEnumRoleValue(role),
              city: userData?.city ?? "",
              phone: userData?.phone ?? "",
              dob: userData?.date_of_birth ?? "",
            }}
          >
            {({
              handleSubmit,
              handleChange,
              handleBlur,
              values,
              errors,
              isSubmitting,
            }) => (
              <Form
                noValidate
                onSubmit={(event) => {
                  event.preventDefault();
                  handleSubmit();
                }}
              >
                <div>
                  <Form.Group className="mt-2" controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="name"
                      className="bg-light"
                      value={values.name}
                      placeholder="John Doe"
                      onChange={(e) => {
                        setName(e.target.value);
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      isInvalid={!!errors.name}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.name}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      Looks Good!
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="city" className="mt-2">
                    <Form.Label>City</Form.Label>
                    <Form.Control
                      type="city"
                      className="bg-light"
                      value={values.city}
                      placeholder="New Delhi"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.city}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.city}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      Looks Good!
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mt-2" controlId="phone">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="phone"
                      className="bg-light"
                      value={values.phone}
                      placeholder="1234567890"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.phone}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      Looks Good!
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group controlId="dob" className="mt-2">
                    <Form.Label>Date of Birth</Form.Label>
                    <Form.Control
                      type="date"
                      className="bg-light"
                      value={values.dob}
                      placeholder="dob"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.dob}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.dob}
                    </Form.Control.Feedback>
                    <Form.Control.Feedback type="valid">
                      Looks Good!
                    </Form.Control.Feedback>
                  </Form.Group>

                  <div className="text-center">
                    <Button
                      variant="secondary"
                      className="mt-2 rounded-2"
                      block
                      size="lg"
                      type="submit"
                      disabled={isSubmitting}
                      style={{ color: "white" }}
                    >
                      Save
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
}
