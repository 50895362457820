import {
  FunctionComponent,
  useContext,
  useEffect,
  useState,
} from "react";
import ProofRead from "./Components/proofread-mode/ProofRead";
import QuestionTopNavBar from "./Components/QuestionTopNavBar/QuestionTopNavBar";
import {
  TAssessmentContext,
  AssessmentContext,
} from "../../context/AssessmentContext";
import { ERole } from "../../utils/Enums";
import { AppContext, TAppContext } from "../../context/AppContext";
import { Container } from "react-bootstrap";
import { Button, Link, Typography } from "@mui/material";
import ExportToDocModal from "./Components/ExportDocModal/ExportDocModal";

interface QuestionIngestionScreenProps {}

const QuestionIngestionScreen: FunctionComponent<
  QuestionIngestionScreenProps
> = () => {
  const [proofRead, setProofRead] = useState<boolean>(false);
  const [showUploadImageModal, setShowUploadImageModal] =
    useState<boolean>(false);
  const { selectedAssessment, showQNavDrawer, setShowQNavDrawer } =
    useContext<TAssessmentContext>(AssessmentContext); // Get the data from the context
  const { setDrawerOpen } = useContext<TAppContext>(AppContext);

  const [downloadLink, setDownloadLink] = useState<string>("");

  useEffect(() => {
    setDrawerOpen(false);
    return () => setDrawerOpen(true);
  });

  return (
    <Container fluid className="p-0">
      <QuestionTopNavBar
        role={ERole.TEACHER}
        assessmentName={selectedAssessment.name}
        showQNavDrawer={showQNavDrawer}
        setShowQNavDrawer={setShowQNavDrawer}
        proofRead={true}
        setProofRead={setProofRead}
        setShowSubmitModal={() => {}}
        edit={false}
        setEdit={() => {}}
        handleSubmit={() => {}}
        loading={false}
        setLoading={() => {}}
      />

      <ProofRead proofRead={true} setProofRead={setProofRead}>
        <Container
          fluid
          className="p-0 d-flex flex-column align-items-center justify-content-center"
          style={{
            height: "100%",
          }}
        >
          <Typography variant="h5" className="mb-4" textAlign={"center"}>
            Start taking Screenshots on the PDF to the right using Win+Shift+S,
            then click on the upload image button
          </Typography>
          <Button
            variant="contained"
            onClick={() => {
              setDownloadLink("");
              setShowUploadImageModal(!showUploadImageModal);
            }}
          >
            Upload Image
          </Button>

          {downloadLink && (
            <Link href={downloadLink} download className="mt-3">
              {downloadLink.split("/")[4].split("-")[0]}....doc
            </Link>
          )}
        </Container>
      </ProofRead>
      <ExportToDocModal
        showModal={showUploadImageModal}
        setShowModal={setShowUploadImageModal}
        onSuccess={(link) => setDownloadLink(link)}
      />
    </Container>
  );
};

export default QuestionIngestionScreen;
